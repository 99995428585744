.toolbarWrapper {
  position: sticky;
  top: 64px;
  z-index: 1;
  display: flex;
  align-items: center;
  margin: 0 -20px 0 -20px;
  padding: var(--spacing-2) 0;
  border-bottom: solid 1px var(--color-neutral-300);
  background: #fff;

  & button {
    flex: 1 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    & svg {
      margin-right: var(--spacing-2);
    }
  }

  & > span {
    height: 24px;
    width: 1px;
    background: var(--color-neutral-300);
  }

  @media (--tablet) {
    top: 72px;
    margin: 0 -24px;
  }

  @media (--tablet-l) {
    top: 112px;
    margin: 0 -32px;
  }

  &[data-has-primary-nav='true'] {
    @media (--tablet) {
      top: 64px;
    }
    @media (--tablet-l) {
      top: 72px;
    }
  }

  @media (--desktop) {
    display: none;
  }

  [data-service-consumer='native'] & {
    top: 0;
  }
  [data-has-footer-sticky='true'] & {
    top: 0;
  }
}

.borderTop {
  border-top: solid 1px var(--color-neutral-300);
  z-index: 11;
  @media (--mobile) {
    z-index: 53;
  }
  @media (--tablet) {
    z-index: 54;
  }
}

.loading {
  height: 22px;
  width: 100vw;
  flex: 1;
}
